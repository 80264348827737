// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import AuthScreensLayout from '../layouts/AppLayout';
import { AuthCardView, Headline } from '../components/auth';
import { FormInput } from '../components/forms';
import { MainButton } from '../components/buttons';
import useResetPassword from '../react-query/hooks/useResetPassword';
import LoadingLayout from '../layouts/loadingLayout/LoadingLayout';
import SimpleAlert from '../components/Notification/SimpleAlert';
import { nanoid } from 'nanoid';


const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('New password is required')
    .min(8, 'New password must be at least 8 characters')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[!@#$%^&*()_+}{:;'?/.,\[\]\-])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).{8,}$/,
      'New Password must contain at least one special character, one number, one uppercase letter, and one lowercase letter'
    )
    .label('Password'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .label('Confirm password')
});

const NewPasswordScreen = () => {
  const { role } = useParams();
  const { mutate, isLoading,data } = useResetPassword(role);
  const navigate = useNavigate();
  const { fromUml } = useParams();
  // const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema,
    onSubmit: async (val) => {
      mutate({
        password: val.newPassword,
        confirmPassword: val.confirmPassword
      });
    }
  });

  return (
    <LoadingLayout isLoading={isLoading}>
      <AuthScreensLayout>
        <Headline title={'Set a new password'} subtitle={'Enter a new password for your account'} />
        {data?.data.isActivated && (
          <SimpleAlert>
            <p>Your password has been reset successfully. Redirecting...</p>
          </SimpleAlert>
        )}
        <AuthCardView>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={formik.handleSubmit}>
              <Stack direction={'column'} spacing={3}>
                <FormInput
                  name={'newPassword'}
                  isPassword
                  label={'password'}
                  fullWidth
                  hasMessage
                  placeholder={'Enter your password'}
                />

                <FormInput
                  name={'confirmPassword'}
                  isPassword
                  label={'confirm password'}
                  fullWidth
                  hasMessage
                  placeholder={'Confirm your password'}
                />
                <MainButton label={'Reset'} color={'primary'} fullWidth type={'submit'} />
              </Stack>
            </Form>
          </FormikProvider>
        </AuthCardView>
        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} marginTop={3}>
          <Typography
            variant={'body1'}
            component={'span'}
            color={'#667085'}
            fontSize={'1.4rem'}
            fontWeight={400}
            display={'inline-block'}
          >
            Remember your Password?{' '}
            <Typography
              variant={'body1'}
              component={'span'}
              color={'#3B7C0F'}
              fontSize={'1.4rem'}
              fontWeight={500}
              sx={{ cursor: 'pointer' }}
              onClick={() => navigate(`/oauth/authorize/login/fromUmlAssign/${fromUml}/initiator_id/${nanoid(35)}`)}
            >
              Log in
            </Typography>
          </Typography>
        </Stack>
      </AuthScreensLayout>
    </LoadingLayout>
  );
};

export default NewPasswordScreen;
