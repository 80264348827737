import React from 'react';
import Spinner from '../../components/spinners/Spinner';
import './loadingLayout.css';
interface Props {
  isLoading: boolean;
  children: React.ReactNode;
}
const LoadingLayout = ({ isLoading, children }: Props) => {
  return (
    <div>
      {isLoading && (
        <div className='blur-overlay'>
          <Spinner />
        </div>
      )}

      {children}
    </div>
  );
};

export default LoadingLayout;
