import { Store } from 'react-notifications-component';
import { notification } from '../../utils/notification';

const snackBar = (data: string) => {
  Store.addNotification({
    ...notification,
    type: 'success',
    container: 'top-center',
    insert: 'top',
    title: 'Success',
    message: data
  });
};

export default snackBar;
