// import apiClient from "../services/api-client";
import { APIClient } from '../services/apiClient';
import { useMutation } from '@tanstack/react-query';
import Response from '../response/response';
import ErrorResponse from '../response/error';

export interface ForgetOtpProps {
  otp: string;
  userId: string;
}
export interface ApiForgetOtpResponse {
  success: boolean;
  message: string;
  data: {
    statusCode: number;
    user: {
      id: number;
    };
  };
  errors: null;
}
const apiClient = new APIClient<ApiForgetOtpResponse, ForgetOtpProps>('/account/forgot_password_verify');
const response = new Response();
const errorResponse = new ErrorResponse();
class RequestError extends Error {
  response: any; // Adjust the type based on the response structure you expect

  constructor(message: string, response: any) {
    super(message);
    this.response = response;
  }
}

const useVerifyForgetOtp = () => {
  return useMutation<ApiForgetOtpResponse, RequestError, ForgetOtpProps>(
    (newData: ForgetOtpProps) => apiClient.post(newData),
    {
      onSuccess: (data, variable) => {
        
        response.forgetPasswordVerifyOtpResponse(data);
      },
      onError: (error) => {
        if (error.response) {
          console.log('An error occurred:', error.response.data.message);
          errorResponse.forgetPasswordVerifyOtpError(error.response.data.message);
        }
      }
    }
  );
};
export default useVerifyForgetOtp;
