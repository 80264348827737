import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { IRootReducerState } from './IRootReducer';

const rootReducer = (history: any) =>
  combineReducers<IRootReducerState>({
    router: connectRouter(history)
    // auth: {}
  });

export default rootReducer;
