import React from 'react';
import { Container, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LOGO from '../assets/logo.png';
import LOGO_ICON from '../assets/bigLogoIcon.png';

interface IAuthScreenProps {
  children: React.ReactNode;
}

const AuthScreensLayout = ({ children, ...otherProps }: IAuthScreenProps) => {
  const navigate = useNavigate();

  return (
    <Container maxWidth={'xl'}>
      <Box marginY={4}>
        <Box component={'img'} src={LOGO} sx={{ cursor: 'pointer' }} />
      </Box>

      <Box marginY={5} width={['100%', '555px']} marginX={'auto'} {...otherProps}>
        {children}
      </Box>

      <Box position={'fixed'} bottom={-80} left={-50} zIndex={-1}>
        <Box component={'img'} sx={{ opacity: 0.3 }} height={368.87} width={320.59} src={LOGO_ICON} />
      </Box>
    </Container>
  );
};

export default AuthScreensLayout;
