// import apiClient from "../services/api-client";
import { APIClient } from '../services/apiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Response from '../response/response';
import ErrorResponse from '../response/error';

export interface Props {
  identity: string;
}
export interface forgetPasswordResponse {
  success: boolean;
  message: string;
  data: {
    statusCode: number;
    user: {
      id: number;
      forgot_password_token: string;
    };
  };
  errors: null;
}

class RequestError extends Error {
  response: any; // Adjust the type based on the response structure you expect

  constructor(message: string, response: any) {
    super(message);
    this.response = response;
  }
}
const apiClient = new APIClient<forgetPasswordResponse, Props>('/account/forgot_password');
const useForgetPassword = () => {
  const response = new Response();
  const errorResponse = new ErrorResponse();
  return useMutation<forgetPasswordResponse, RequestError, Props>((newData: Props) => apiClient.post(newData), {
    onSuccess: (data, variable) => {
      response.forgetPasswordResponse(data, variable);
    },
    onError: (error) => {
      if (error.response) {
        console.log('An error occurred:', error.response.data.message);
        // errorResponse.forgetPasswordError(error.response.data.message);
      }
    }
  });
};
export default useForgetPassword;
