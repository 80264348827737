import React, { useState } from 'react';
import { Box, Divider, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';

interface TabPropsComp {
  tabs: { value: string; tab: string }[];
  firstChild: React.ReactNode;
  secondChild: React.ReactNode;
}

const TabContainer = styled(Box)({
  // '@media screen and (max-width: 1200px)': {
  //   display: 'none'
  // }
});

const TabStyle = styled(Tab)(() => ({
  fontSize: '1.4rem',
  fontFamily: 'Inter',
  fontWeight: 500,
  color: '#667085',
  textTransform: 'capitalize',
  '&.Mui-selected': {
    color: '#3B7C0F'
  },
  '&:not(:last-of-type)': {
    marginRight: '0rem'
  },
  '@media (min-width: 600px)': {
    minWidth: 48
  }
}));

const TabComponent = ({ tabs, firstChild, secondChild }: TabPropsComp) => {
  const [value, setValue] = useState<string>('1');

  const handleChangeTab = (event: any, newValue: string) => setValue(newValue);
  return (
    <Box>
      <TabContainer>
        <TabContext value={value}>
          <TabList
            variant='fullWidth'
            onChange={handleChangeTab}
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: '#3B7C0F'
              }
            }}
          >
            {tabs.map(({ value, tab }) => (
              <TabStyle
                key={value}
                value={value}
                label={tab}
                disableRipple
                sx={{
                  minWidth: 'auto'
                }}
              />
            ))}
          </TabList>
          <Divider sx={{ mb: 2 }} />
          <TabPanel value={'1'} sx={{ padding: 0 }}>
            {firstChild}
          </TabPanel>
          <TabPanel value={'2'} sx={{ padding: 0 }}>
            {secondChild}
          </TabPanel>
        </TabContext>
      </TabContainer>
    </Box>
  );
};

export default TabComponent;
