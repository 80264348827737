import axios from 'axios';
import Cookies from 'js-cookie';
import { nanoid } from 'nanoid';
import { Store } from 'react-notifications-component';
import { notification } from '../../utils/notification';
import { setSession } from '../../utils/jwt';
import { ApiResponse, Props } from '../hooks/useRegister';
import { LoginResponse } from '../hooks/useLogin';
import { forgetPasswordResponse, Props as newProps } from '../hooks/useForgetPassword';
import { ApiForgetOtpResponse } from '../hooks/useVerifyForgetOtp';
import { ApiResponse as props } from '../hooks/useVerifyUser';

class Response {
  registerResponse = (data: ApiResponse, user: Props) => {
    Store.addNotification({
      ...notification,
      type: 'success',
      container: 'top-center',
      insert: 'top',
      title: 'Success',
      message: 'successfully registered'
    });
    user.email &&
      setTimeout(() => {
        window.location.href = `/oauth/authorize/verification/automatedPin/${data?.message?.token}/${user.email}/${1}/${
          user.roleId
        }/${'email'}`;
      }, 1000);
    user.phone &&
      setTimeout(() => {
        window.location.href = `/oauth/authorize/verification/automatedPin/${data?.message?.token}/${user.phone}/${1}/${
          user.roleId
        }/${'phone_number'}`;
      }, 1000);
  };
  emailVerificationResponse = (data: props) => {
    // setSession(payload);
    // Cookies.set('firstTimer', '1', {
    //   sameSite: 'strict',
    //   Domain: 'gogeeper.com',
    //   priority: 'high',
    //   secure: false
    // });
    Store.addNotification({
      ...notification,
      type: 'success',
      container: 'top-center',
      insert: 'top',
      title: 'Success',
      message: data.message
    });
    if (data.data) {
      setSession(data.data?.token);
      Cookies.set('user_role', data.data.role, {
        // sameSite: 'strict',
        // Domain: 'gogeeper.com',
        // priority: 'high',
        // secure: false
        expires: 1
      });

      setTimeout(() => {
        window.close();
      }, 1000);
    }
  };
  loginResponse = (data: LoginResponse) => {
    if (data.data?.isActivated) {
      setSession(data.data?.token);
      Cookies.set('user_role', data.data.role, {
        // sameSite: 'strict',
        // Domain: 'gogeeper.com',
        // priority: 'high',
        // secure: false
        expires: 1
      });
      Cookies.set("is_logged_in", 'true')

      setTimeout(() => {
        window.close();
      }, 1000);
    }

    // if (!data.otp_verified || data.data['Login Data']?.otp_verified === 0) {
    //   const unverified = data.data['Unverified Login Data'];

    //   setTimeout(() => {
    //     window.location.href = `/oauth/authorize/verification/${data.data.pinId || 'automatedPin'}/${
    //       data.data?.token
    //     }/${unverified?.phone_number || unverified?.email}/${unverified?.id}/${unverified?.role}/${
    //       unverified?.email === null ? 'phone_number' : 'email'
    //     }`;
    //   }, 1000);
    // }
  };
  //******** */ note this endpoint is expecting the role....
  forgetPasswordResponse = (data: forgetPasswordResponse, variable: newProps) => {
    Store.addNotification({
      ...notification,
      type: 'success',
      container: 'top-center',
      insert: 'top',
      title: 'Success',
      message: data.message
    });
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setTimeout(() => {
      window.location.href = `/oauth/authorize/password-reset-otp/${'automatedPin'}/${data?.data.user.id}/${
        emailRegex.test(variable.identity) ? 'email' : 'phone_number'
      }/${'role'}/${data?.data.user.forgot_password_token}`;
    }, 1000);
  };
  forgetPasswordVerifyOtpResponse = (data: ApiForgetOtpResponse) => {
    console.log(data, 'this is the response getting here');
    Store.addNotification({
      ...notification,
      type: 'success',
      container: 'top-center',
      insert: 'top',
      title: 'Success',
      message: data.message
    });
    setTimeout(() => {
      window.location.href = `/oauth/authorize/change-password/${data.data.user.id}/${nanoid(35)}`;
    }, 1000);
    // setTimeout(() => {
    //   window.location.href = `/oauth/authorize/change-password/${payload.role}/${nanoid(35)}`;
    // }, 1000);
  };
  resetPasswordResponse = (data: any) => {
    Store.addNotification({
      ...notification,
      type: 'success',
      container: 'top-center',
      insert: 'top',
      title: 'Success',
      message: data.message
    });
    setTimeout(() => {
      window.location.href = `/oauth/authorize/login/fromUmlAssign/${`MarketPlace`}/initiator_id/${nanoid(35)}`;
    }, 1000);
  };
  cleanPasswordResponse = (data: any) => {
    setTimeout(() => {
      window.location.href = `/oauth/authorize/login/fromUmlAssign/${`MarketPlace`}/initiator_id/${nanoid(35)}`;
    }, 1000);
  };
}
export default Response;
