import React from 'react';
import { Box, Stack } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
// import { useDispatch } from 'react-redux';
import { CustomCheckbox, FormInput } from '../forms';
import { MainButton } from '../buttons';
import { StyledLink } from '../buttons/styledLink';
import { useDispatch } from 'react-redux';
import { LOGIN } from '../../redux/slice/auth/auth.actions';
import { UseMutateFunction } from '@tanstack/react-query';
import { LoginResponse, RequestProps, RequestError } from '../../react-query/hooks/useLogin';
// import { LOGIN } from '../../redux/slice/auth/auth.action';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Required').label('Email'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[!@#$%^&*()_+}{:;'?/.,\[\]\-])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).{8,}$/,
      'Password must contain at least one special character, one number, one uppercase letter, and one lowercase letter'
    )
    .label('Password')
});

interface Props {
  role: any;
  mutate: UseMutateFunction<LoginResponse, RequestError, RequestProps, unknown>;
  isLoading: boolean;
}

const EmailLogin = ({ role, mutate, isLoading }: Props) => {
  const [checked, setChecked] = React.useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      phone: ''
    },
    validationSchema,
    onSubmit: async (val) => {
      const newVal = {
        email: val.email,
        password: val.password,
        phone: ''
      };
      mutate({
        ...newVal
      });
      // dispatch(LOGIN(newVal));
    }
  });
  return (
    <Box>
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <FormInput
            name={'email'}
            type='email'
            label={'email'}
            pattern='[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$'
            hasMessage
            fullWidth
            placeholder={'you@user.com'}
            mb={3}
          />
          <FormInput
            name={'password'}
            isPassword
            label={'password'}
            fullWidth
            hasMessage
            mb={2}
            placeholder={'Enter your password'}
          />
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
            <CustomCheckbox
              label={'Remember me'}
              checked={checked}
              onChange={() => setChecked(!checked)}
              fontSize={'1.4rem'}
            />
            <StyledLink to={`/oauth/authorize/forgot-password/fromUmlAssign/${role}`}>Forgot password?</StyledLink>
          </Stack>
          <MainButton
            label={'Log In'}
            type={'submit'}
            color={'primary'}
            fullWidth
            padding={'10px 18px'}
            loading={isLoading}
          />
        </Form>
      </FormikProvider>
    </Box>
  );
};

export default EmailLogin;
