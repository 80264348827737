import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { nanoid } from 'nanoid';
import { Stack, Typography, styled } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { Link, useParams } from 'react-router-dom';
import AuthScreensLayout from '../layouts/AppLayout';
import { AuthCardView, Headline } from '../components/auth';
import { FormInput } from '../components/forms';
import { MainButton } from '../components/buttons';
import { FORGOT_PASSWORD } from '../redux/slice/auth/auth.actions';
import useForgetPassword from '../react-query/hooks/useForgetPassword';
import LoadingLayout from '../layouts/loadingLayout/LoadingLayout';
import ErrorNotification from '../components/Notification/ErrorNotification';

const validationSchema = Yup.object().shape({
  phone_number: Yup.string().required('Phone number or Email is required')
});

const StyledLink = styled(Link)({
  color: '#3B7C0F',
  fontWeight: 500,
  fontSize: '1.4rem',
  textDecoration: 'none',
  fontFamily: 'Inter',

  '&:hover': {
    borderBottom: '1.5px solid #3B7C0F'
  }
});

// Use the useResendOtp hook only if the token exists
// const [identity, setIdentity] = useState('');
// const query = identity
//   ? useForgetPassword(identity)
//   : { data: null, isLoading: false, error: null, refetch: undefined };

const ForgetPassword = () => {
  const { mutate, isLoading, error } = useForgetPassword();
  const dispatch = useDispatch();
  const { fromUml } = useParams();
  const formik = useFormik({
    initialValues: {
      phone_number: ''
    },
    validationSchema,
    // onSubmit: async (val) => dispatch(FORGOT_PASSWORD(val.phone_number))
    onSubmit: async (val) => {
      mutate({ identity: val.phone_number });
    }
  });

  return (
    <LoadingLayout isLoading={isLoading}>
      <AuthScreensLayout>
        <Headline title={'Forgot Password?'} subtitle={'A reset code would be sent to you'} />
        {error && (
          <ErrorNotification>
            <p>{error.response.data.message || error.message}</p>
          </ErrorNotification>
        )}

        <AuthCardView>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={formik.handleSubmit}>
              <FormInput
                name={'phone_number'}
                label={'phone number or Email'}
                hasMessage
                fullWidth
                placeholder={'you@user.com or 080000000'}
                mb={3}
                type='text'
              />
              <MainButton
                label={'Send Code'}
                type={'submit'}
                color={'primary'}
                fullWidth
                padding={'10px 18px'}
                loading={isLoading}
              />
            </Form>
          </FormikProvider>
        </AuthCardView>
        <Stack direction={'row'} spacing={1} marginY={3} alignItems={'center'} justifyContent={'center'}>
          <Typography color={'#667085'} fontWeight={400} fontSize={'1.4rem'}>
            Remember your Password?
          </Typography>
          <StyledLink to={`/oauth/authorize/login/fromUmlAssign/${fromUml}/initiator_id/${nanoid(35)}`}>
            Log in
          </StyledLink>
        </Stack>
      </AuthScreensLayout>
    </LoadingLayout>
  );
};

export default ForgetPassword;
