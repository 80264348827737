// import apiClient from "../services/api-client";
import { APIClient } from '../services/apiClient';
import { useMutation } from '@tanstack/react-query';
import Response from '../response/response';
import ErrorResponse from '../response/error';

interface Props {
  password: string;
  confirmPassword: string;
}
export interface ResetPasswordApiResponse {
  success: boolean;
  message: string;
  errors: null;
  data: {
    statusCode: number;
    token: string;
    isActivated: boolean;
    role: string;
  };
}

const response = new Response();
const errorResponse = new ErrorResponse();
class RequestError extends Error {
  response: any; // Adjust the type based on the response structure you expect

  constructor(message: string, response: any) {
    super(message);
    this.response = response;
  }
}

const useResetPassword = (userId: string) => {
  const apiClient = new APIClient<ResetPasswordApiResponse, Props>(`/account/reset_password/${userId}`);
  return useMutation<ResetPasswordApiResponse, RequestError, Props>((newData: Props) => apiClient.post(newData), {
    onSuccess: (data, variable) => {
     
      setTimeout(()=>{
         response?.loginResponse(data);
      }, 2000)
     
    },
    onError: (error) => {
      if (error.response) {
        console.log('An error occurred:', error.response.data.message);
        errorResponse.resetPasswordError(error.response.data.message);
      }
    }
  });
};
export default useResetPassword;
