// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import AuthScreensLayout from '../layouts/AppLayout';
import { AuthCardView, Headline } from '../components/auth';
import { FormInput } from '../components/forms';
import { MainButton } from '../components/buttons';
import useCleanPassword from '../react-query/hooks/useCleanPassword';
import LoadingLayout from '../layouts/loadingLayout/LoadingLayout';
import SuccessNotification from '../components/Notification/SuccessNotification';
import ErrorNotification from '../components/Notification/ErrorNotification';

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required(),
  password: Yup.string()
    .required('Required')
    .label('Password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character (@ $ ! % * ? &)'
    ),
  confirmPassword: Yup.string()
    .required('Required')
    .label('Confirm password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

const CleanPasswordScreen = () => {
  const { token } = useParams();
  const { mutate, isLoading, data, error } = useCleanPassword(token);
  // extract the dirty password from the session storage
  let defaultPassword = sessionStorage.getItem('dirty');
  defaultPassword = defaultPassword ? JSON.parse(defaultPassword) : '';
  // return savedData ? JSON.parse(savedData)

  const formik = useFormik({
    initialValues: {
      oldPassword: defaultPassword,
      password: '',
      confirmPassword: ''
    },
    validationSchema,
    onSubmit: async (val) => {
      mutate({
        ...val
      });
    }
  });

  return (
    <LoadingLayout isLoading={isLoading}>
      <AuthScreensLayout>
        <Headline title={'Set a new password'} subtitle={'Enter a new password for your account'} />
        {data?.message && (
          <SuccessNotification>
            <p>{data?.message}</p>
          </SuccessNotification>
        )}
        {error && (
          <ErrorNotification>
            <p>{error.response.data.message || error.message}</p>
          </ErrorNotification>
        )}
        <AuthCardView>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={formik.handleSubmit}>
              <Stack direction={'column'} spacing={3}>
                <FormInput
                  name={'oldPassword'}
                  isPassword
                  label={'oldPassword'}
                  fullWidth
                  hasMessage
                  placeholder={'Enter your old password'}
                  readOnly={true}
                />
                <FormInput
                  name={'password'}
                  isPassword
                  label={'password'}
                  fullWidth
                  hasMessage
                  placeholder={'Enter your new password'}
                />

                <FormInput
                  name={'confirmPassword'}
                  isPassword
                  label={'confirm password'}
                  fullWidth
                  hasMessage
                  placeholder={'Confirm your new password'}
                />
                <MainButton label={'Reset'} color={'primary'} fullWidth type={'submit'} loading={isLoading} />
              </Stack>
            </Form>
          </FormikProvider>
        </AuthCardView>
      </AuthScreensLayout>
    </LoadingLayout>
  );
};

export default CleanPasswordScreen;
