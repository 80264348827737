import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
// import { Colors } from './colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    // primary: {
    //     main: Colors.primary
    // },
    // secondary: {
    //     main: Colors.secondary
    // },
    error: {
      main: red.A400
    }
  },
  typography: {
    fontFamily: 'Inter'
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
          textTransform: 'capitalize',
          fontSize: 12,
          borderRadius: 12
        }
      }
    }
  }
});

export default theme;
