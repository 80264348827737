// import apiClient from "../services/api-client";
import { APIClient } from '../services/apiClient';
import { useMutation, useQueryClient, MutateFunction } from '@tanstack/react-query';
import Response from '../response/response';
import ErrorResponse from '../response/error';

export interface RequestProps {
  email?: string;
  phone?: string;
  password: string;
}
export interface LoginResponse {
  success: boolean;
  message: string;
  data: {
    token: string;
    isActivated: boolean;
    role: string;
  } | null;
  errors: null;
}

export class RequestError extends Error {
  response: any; // Adjust the type based on the response structure you expect

  constructor(message: string, response: any) {
    super(message);
    this.response = response;
  }
}
const response = new Response();
const errorResponse = new ErrorResponse();
const apiClient = new APIClient<LoginResponse, RequestProps>('/account/login');
const useLogin = () => {
  return useMutation<LoginResponse, RequestError, RequestProps>((newData: RequestProps) => apiClient.post(newData), {
    onSuccess: (data, variable) => {
      response.loginResponse(data);
    },
    onError: (error, variables) => {
      if (error.response) {
        errorResponse.loginError(error.response, variables.password);
      }
    }
  });
};
export default useLogin;
