import { useQuery } from '@tanstack/react-query';
import { APIClient, setAuthToken } from '../services/apiClient';

interface Props {
  success: boolean;
  message: string;
  errors: null;
}
// Set the token for endpoints that require it

const useResendOtp = (token: string, params: 'UPDATE_PROFILE' | 'ACTIVATION' | 'FORGOT_PASSWORD') => {
  const apiClient = new APIClient<Props>(`/account/resend_otp/${params}`);
  setAuthToken(token);
  // Use the useQuery hook from react-query to fetch data
  return useQuery<Props, Error>({
    queryKey: ['resend_otp'],
    queryFn: apiClient.get,
    enabled: false
  });
};
export default useResendOtp;
