import React from 'react';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Box, Stack } from '@mui/material';
import { CustomCheckbox, FormInput } from '../forms';
import { MainButton } from '../buttons';
import { StyledLink } from '../buttons/styledLink';
import { useDispatch } from 'react-redux';
import { LOGIN } from '../../redux/slice/auth/auth.actions';
// import { useDispatch } from 'react-redux';
// import { LOGIN } from '../../redux/slice/auth/auth.action';
import useLogin from '../../react-query/hooks/useLogin';
import { UseMutateFunction } from '@tanstack/react-query';
import { LoginResponse, RequestProps, RequestError } from '../../react-query/hooks/useLogin';

const validationSchema = Yup.object().shape({
  phone_number: Yup.string()
    .matches(/^[0-9]{11}$/, 'Phone number is invalid')
    .required('Phone number is required'),
  password: Yup.string().required('Required').label('Password').min(8, 'Password must be at least 8 characters')
});
interface Props {
  role: any;
  mutate: UseMutateFunction<LoginResponse, RequestError, RequestProps, unknown>;
  isLoading: boolean;
}
const PhoneNumberLogin = ({ role, mutate, isLoading }: Props) => {
  const [checked, setChecked] = React.useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      phone_number: '',
      password: ''
    },
    validationSchema,
    onSubmit: async (val) => {
      console.log(val);
      mutate({ password: val.password, phone: val.phone_number });
    }
  });

  return (
    <Box>
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <FormInput
            name={'phone_number'}
            label={'phone number'}
            hasMessage
            fullWidth
            placeholder={'08012345678'}
            mb={3}
            pattern='[0-9]*'
            type='tel'
          />
          <FormInput
            name={'password'}
            isPassword
            label={'password'}
            fullWidth
            hasMessage
            mb={2}
            placeholder={'Enter your password'}
          />
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
            <CustomCheckbox
              label={'Remember me'}
              checked={checked}
              onChange={() => setChecked(!checked)}
              fontSize={'1.4rem'}
            />
            <StyledLink to={`/oauth/authorize/forgot-password/fromUmlAssign/${role}`}>Forgot password?</StyledLink>
          </Stack>
          <MainButton label={'Log In'} type={'submit'} color={'primary'} fullWidth padding={'10px 18px'} />
        </Form>
      </FormikProvider>
    </Box>
  );
};

export default PhoneNumberLogin;
