import React from 'react';
import { Button } from '@mui/material';
import Spinner from '../spinners/Spinner';

interface Props {
  label: React.ReactNode;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'primary' | 'secondary' | 'inherit';
  mr?: number;
  padding?: string;
  fontSize?: string | any;
  loading?: boolean;
  isDisabled?: boolean;

  [x: string]: any;
}

const ButtonStyle = {
  textTransform: 'none',
  borderRadius: '0.8rem',
  fontWeight: '500',
  padding: '.6rem 1.8rem'
};
const boxShadow = `0 1px 2px rgba(16, 24, 40, 0.05)`;

const MainButton = ({
  label,
  loading = false,
  isDisabled = false,
  variant = 'outlined',
  fontSize = '1.6rem',
  padding,
  color,
  mr,
  ...otherProps
}: Props) => {
  return (
    <Button
      sx={{
        ...ButtonStyle,
        fontSize: fontSize,
        padding: padding ? padding : ButtonStyle.padding,
        borderColor: variant === 'outlined' ? '#D0D5DD' : 'none',
        backgroundColor: color === 'primary' ? '#4CA30D' : 'transparent',
        boxShadow: variant === 'text' ? 'none' : boxShadow,
        margin: `0 ${mr}rem 0 0`,
        color: color === 'primary' ? '#fff' : color === 'secondary' ? '#667085' : '#4CA30D',
        '&:hover': {
          backgroundColor: color === 'primary' ? '#4CA30D' : '#F5F7FA',
          borderColor: color === 'primary' ? '#4CA30D' : '#D0D5DD',
          color: variant === 'text' ? '#4CA30D' : ''
        }
      }}
      variant={variant}
      {...otherProps}
    >
      {loading ? <p>loading....</p> : label}
    </Button>
  );
};

export default MainButton;
