import React, { useState } from 'react';
import { FormControl, FormLabel, Typography, Box } from '@mui/material';
import { BiErrorCircle } from 'react-icons/bi';
import { useFormikContext } from 'formik';
import { startCase } from 'lodash';
import { ErrorText } from './ErrorText';
import { FormWrapper, LabelStyle, FormField } from './formWrapper.style';
import { styled } from '@mui/material/styles';

interface IProps {
  name: string;
  mb?: string | number;
  hasMessage?: boolean;
  label?: string;
  isPassword?: boolean;
  fullWidth?: boolean;
  readOnly?: boolean;

  [propName: string]: any;
}

const StyledInputClickable = styled(Typography)({
  color: '#344054',
  fontSize: '1.2rem',
  cursor: 'pointer',
  fontWeight: 400,
  textTransform: 'capitalize',
  '&:hover': {
    color: '#27303e'
  }
});

const FormInput = ({ isPassword, name, hasMessage, label, fullWidth, mb, readOnly = false, ...otherProps }: IProps) => {
  const { touched, errors, values } = useFormikContext<IProps>();

  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  const handleShowPassword = () => {
    setIsShowPassword((show) => !show);
  };

  return (
    <FormControl fullWidth={fullWidth} sx={{ mb }}>
      <Typography component={FormLabel} sx={{ ...LabelStyle }}>
        {startCase(label)}
      </Typography>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          ...FormWrapper,
          borderColor: touched[name] && errors[name] ? '#B42318' : '#D0D5DD'
        }}
      >
        <FormField
          name={name}
          {...otherProps}
          value={values[name]}
          readOnly={readOnly}
          {...(isPassword && { type: isShowPassword ? 'text' : 'password' })}
        />
        {hasMessage ? (
          isPassword ? (
            <Typography component={'span'} className='formInput__icon' onClick={handleShowPassword}>
              {!isShowPassword ? (
                <StyledInputClickable>show</StyledInputClickable>
              ) : (
                <StyledInputClickable>hide</StyledInputClickable>
              )}
            </Typography>
          ) : hasMessage && touched[name] && errors[name] ? (
            <BiErrorCircle
              className='formInput__icon'
              size={15}
              fill={'#B42318'}
              style={{
                position: 'absolute',
                right: 4
              }}
            />
          ) : null
        ) : null}
      </Box>
      {hasMessage && <ErrorText error={errors[name]} visible={touched[name]} />}
    </FormControl>
  );
};

export default FormInput;
