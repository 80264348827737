import React from 'react';
import { useParams } from 'react-router-dom';
import AuthScreensLayout from '../layouts/AppLayout';
import LoadingLayout from '../layouts/loadingLayout/LoadingLayout';
import { AuthCardView, EmailLogin, FooterLink, Headline, TabComponent } from '../components/auth';
import PhoneNumberLogin from '../components/auth/PhoneNumberLogin';
import useLogin from '../react-query/hooks/useLogin';
import SuccessNotification from '../components/Notification/SuccessNotification';
import ErrorNotification from '../components/Notification/ErrorNotification';

const tabs = [
  {
    tab: 'email',
    value: '1'
  },
  {
    tab: 'phone number',
    value: '2'
  }
];

const LoginScreen = () => {
  const { role, initiator_id } = useParams();
  const { mutate, isLoading, data, error } = useLogin();
  return (
    <LoadingLayout isLoading={isLoading}>
      <AuthScreensLayout>
        <Headline title={'Welcome Back!'} subtitle={'Enter the details below correctly to log in'} />
        {data?.message && (
          <SuccessNotification>
            <p>{data?.message}</p>
          </SuccessNotification>
        )}
        {error && (
          <ErrorNotification>
            <p>{error?.response?.data?.message || error?.message}</p>
          </ErrorNotification>
        )}

        <AuthCardView>
          <TabComponent
            tabs={tabs}
            firstChild={<EmailLogin role={role} mutate={mutate} isLoading={isLoading} />}
            secondChild={<PhoneNumberLogin role={role} mutate={mutate} isLoading={isLoading} />}
          />
        </AuthCardView>
        <FooterLink
          to={`/oauth/authorize/onboarding/fromUmlAssign/${role}/initiator_id/${initiator_id}`}
          linkText={'Sign up'}
          grayText={'Don’t have an account?'}
        />
      </AuthScreensLayout>
    </LoadingLayout>
  );
};

export default LoginScreen;
