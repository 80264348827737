import React from 'react';
import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

interface CardProps {
  children: React.ReactNode;
}

const StyledCard = styled(Card)({
  width: '100%',
  height: '100%',
  boxShadow: `0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06)`,
  borderRadius: '12px',
  padding: `32px 40px`,
  backgroundColor: '#FFFFFF',
  zIndex: 99
});

const AuthCardView = (props: CardProps) => {
  return <StyledCard elevation={0}>{props.children}</StyledCard>;
};

export default AuthCardView;
