import { styled } from '@mui/material/styles';
import { Field } from 'formik';

const FormWrapper = {
  border: '1px solid #D0D5DD',
  padding: ' .7rem .5rem',
  borderRadius: '8px',
  boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.05)`,
  position: 'relative'
};

const LabelStyle = {
  color: '#344054',
  fontWeight: 500,
  fontSize: '1.4rem',
  lineHeight: 2,

  '@media screen and (max-width: 1200px)': {
    fontSize: '1.2rem',
    lineHeight: 1.8
  }
};

const FormField = styled(Field)({
  border: 'none',
  outline: 'none',
  fontSize: '1.4rem',
  padding: '.5rem 0.2rem',
  width: '90%',
  // flex: 1,
  '&:focus': {
    outline: 'none'
  },

  '&::placeholder': {
    color: '#667085',
    fontWeight: 500
  }
});

export { FormWrapper, LabelStyle, FormField };
