import React from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { nanoid } from 'nanoid';
import { useNavigate, useParams } from 'react-router-dom';
import AppLayout from '../layouts/AppLayout';
import { AuthCardView, FooterLink, Headline } from '../components/auth';
import { CheckboxWithLabelSelector } from '../components/forms';
import { MainButton } from '../components/buttons';

const validationSchema = Yup.object().shape({
  onboarding: Yup.string().required('please select an option').label('On-boarding')
});

const onboard_options = [
  {
    value: 'Farmer',
    label: 'As a Farmer'
  },
  {
    value: 'AgricExpert',
    label: 'As an Agric Expert'
  },
  {
    value: 'Marketplace',
    label: 'Buyer'
  }
];

const OnboardingScreen = () => {
  const navigate = useNavigate();
  const { fromUml } = useParams();

  const formik = useFormik({
    initialValues: {
      onboarding: fromUml === 'Marketplace' ? 'Marketplace' : ''
    },
    validationSchema,
    onSubmit: async (val) => {
      navigate(`/oauth/authorize/register/${val.onboarding}/${fromUml}`);
    }
  });

  return (
    <AppLayout>
      <Headline
        title={'How do you plan to use Go-Geeper?'}
        subtitle={'We will tailor your experience to your preferences.'}
      />
      <AuthCardView>
        <FormikProvider value={formik}>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <CheckboxWithLabelSelector formik={formik} dataToRender={onboard_options} name={'onboarding'} />
            <MainButton label={'Continue'} type={'submit'} color={'primary'} fullWidth padding={'10px 18px'} />
          </Form>
        </FormikProvider>
      </AuthCardView>
      <FooterLink
        to={`/oauth/authorize/login/fromUmlAssign/${fromUml}/initiator_id/${nanoid(35)}`}
        linkText={'Sign in'}
        grayText={'Have an account?'}
      />
    </AppLayout>
  );
};

export default OnboardingScreen;
