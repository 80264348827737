// import apiClient from "../services/api-client";
import { APIClient, setAuthToken } from '../services/apiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Response from '../response/response';
import ErrorResponse from '../response/error';

export interface Props {
  otp: string;
}
export interface ApiResponse {
  success: boolean;
  message: string;
  data: {
    token: string;
    isActivated: boolean;
    role: string;
  } | null;
  errors: null;
}
const apiClient = new APIClient<ApiResponse, Props>('/account/activate');
const response = new Response();
const errorResponse = new ErrorResponse();
class RequestError extends Error {
  response: any; // Adjust the type based on the response structure you expect

  constructor(message: string, response: any) {
    super(message);
    this.response = response;
  }
}

const useVerifyUser = (token: string | undefined) => {
  token && setAuthToken(token);
  return useMutation<ApiResponse, RequestError, Props>((newData: Props) => apiClient.post(newData), {
    onSuccess: (data, variable) => {
      response.emailVerificationResponse(data);
      console.log(data);
    },
    onError: (error) => {
      if (error.response) {
        console.log('An error occurred:', error.response.data.message);
        errorResponse.emailVerificationError(error.response.data.message);
      }
    }
  });
};
export default useVerifyUser;
