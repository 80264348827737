import axios from 'axios';
import Cookies from 'js-cookie';
import { nanoid } from 'nanoid';
import { Store } from 'react-notifications-component';
import { notification } from '../../utils/notification';
import { setSession } from '../../utils/jwt';

class ErrorResponse {
  registerError = (error: string) => {
    Store.addNotification({
      ...notification,
      type: 'danger',
      container: 'top-center',
      insert: 'top',
      title: 'Error',
      message: error
    });
  };
  loginError = (error: any, defaultPassword?: string) => {
    // Store.addNotification({
    //   ...notification,
    //   type: 'danger',
    //   container: 'top-center',
    //   insert: 'top',
    //   title: 'Error',
    //   message:
    //     error?.data.message === 'Account not activated'
    //       ? 'Account not activated and you will be redirected to verification'
    //       : error?.data.message
    // });
    if (error?.data.message === 'Account not activated') {
      setTimeout(() => {
        window.location.href = `/oauth/authorize/verification/automatedPin/${
          error.data.errors.token
        }/${'user.email'}/${1}/${error.data.errors.role}/${'email'}`;
      }, 1000);
    }

    if (error?.data.message === 'default password still available') {
      // store dirty password on session storage
      sessionStorage.setItem('dirty', JSON.stringify(defaultPassword));

      setTimeout(() => {
        window.location.href = `/oauth/authorize/clean-up/${error.data.errors.reset_password_token}/${nanoid(35)}`;
      }, 1000);
    }
  };
  emailVerificationError = (error: string) => {
    Store.addNotification({
      ...notification,
      type: 'danger',
      container: 'top-center',
      insert: 'top',
      title: 'Error',
      message: error
    });
  };
  forgetPasswordError = (error: string) => {
    Store.addNotification({
      ...notification,
      type: 'danger',
      container: 'top-center',
      insert: 'top',
      title: 'Error',
      message: error
    });
  };
  forgetPasswordVerifyOtpError = (error: string) => {
    Store.addNotification({
      ...notification,
      type: 'danger',
      container: 'top-center',
      insert: 'top',
      title: 'Error',
      message: error === 'OTP expired' ? 'OTP expired - click on the resend button to generate new otp' : error
    });
  };
  resetPasswordError = (error: string) => {
    Store.addNotification({
      ...notification,
      type: 'danger',
      container: 'top-center',
      insert: 'top',
      title: 'Error',
      message: error
    });
  };
}
export default ErrorResponse;
