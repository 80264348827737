import React from 'react';
import { Box, Typography } from '@mui/material';

interface IProps {
  title: string;
  subtitle: string;
}

const Headline = ({ subtitle, title }: IProps) => {
  return (
    <Box textAlign={'center'} marginBottom={3.2}>
      <Typography color={'#101828'} fontSize={'3rem'} fontWeight={600} gutterBottom>
        {title}
      </Typography>
      <Typography color={'#667085'} fontSize={'1.6rem'} fontWeight={400}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Headline;
