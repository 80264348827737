import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { IoMdCheckbox } from 'react-icons/io';
import { IoSquareOutline } from 'react-icons/io5';

interface IProps {
  label?: string | React.ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
  [propName: string]: any;
  size?: number;
  fontSize?: any;
}

const CustomCheckbox = ({ checked, label, onChange, size = 19, fontSize = '1.2rem', ...otherProps }: IProps) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            icon={<IoSquareOutline size={size} color={'#D0D5DD'} />}
            checkedIcon={<IoMdCheckbox size={size} />}
            onChange={(e) => onChange(e.target.checked)}
            sx={{
              // '& .MuiSvgIcon-root': { fontSize: 24 },
              '&.Mui-checked': {
                color: '#4CA30D'
              }
            }}
          />
        }
        label={
          <Typography color={'#344054'} fontSize={fontSize} {...otherProps} fontWeight={500}>
            {label}
          </Typography>
        }
      />
    </FormGroup>
  );
};

export default CustomCheckbox;
