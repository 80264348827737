import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { StyledLink } from '../buttons/styledLink';

interface IProps {
  grayText: string;
  linkText: string;
  to: string;
}

const FooterLink = (props: IProps) => (
  <Box textAlign={'center'} marginTop={3}>
    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
      <Typography color={'#667085'} fontFamily='Inter' fontSize={['1.2rem', '1.4rem']} fontWeight={400}>
        {props.grayText}
      </Typography>
      <StyledLink style={{ fontWeight: 500, marginLeft: '4px' }} to={props.to}>
        {props.linkText}
      </StyledLink>
    </Stack>
  </Box>
);

export default FooterLink;
