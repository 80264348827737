import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  ForgetPasswordResetCode,
  ForgetPasswordScreen,
  LoginScreen,
  OnboardingScreen,
  RegistrationScreen,
  VerificationScreen,
  NewPasswordScreen
} from '../containers';
import CleanPasswordScreen from '../containers/CleanPasswordScreen';

const Routers = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        <Routes>
          <Route
            path={'/oauth/authorize/onboarding/fromUmlAssign/:fromUml/initiator_id/:initiator_id'}
            element={<OnboardingScreen />}
          />
          <Route
            path={'/oauth/authorize/login/fromUmlAssign/:role/initiator_id/:initiator_id'}
            element={<LoginScreen />}
          />
          <Route path={'/oauth/authorize/register/:role/:fromUml'} element={<RegistrationScreen />} />
          <Route
            path={'/oauth/authorize/verification/:pinId/:token/:phone/:userId/:role/:registrationType'}
            element={<VerificationScreen />}
          />
          <Route path={'/oauth/authorize/forgot-password/fromUmlAssign/:fromUml'} element={<ForgetPasswordScreen />} />
          <Route
            path={'/oauth/authorize/password-reset-otp/:pinId/:userCredential/:registrationType/:role/:token'}
            element={<ForgetPasswordResetCode />}
          />
          <Route path={'/oauth/authorize/clean-up/:token/:initiator_id'} element={<CleanPasswordScreen />} />
          <Route path={'/oauth/authorize/change-password/:role/:initiatorId'} element={<NewPasswordScreen />} />
          <Route path='*' element={<div>404</div>} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default Routers;
