import Alert from '@mui/material/Alert';
import React from 'react';
import { MdCheck } from 'react-icons/md';
interface AlertProps {
  children: React.ReactNode;
}

export default function SimpleAlert({ children }: AlertProps) {
  return (
    <Alert
      icon={<MdCheck fontSize='inherit' />}
      severity='success'
      sx={{
        marginBottom: '10px',
        fontSize:'16px'
      }}
    >
      {children}
    </Alert>
  );
}
