import React, { useState, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import AuthScreensLayout from '../layouts/AppLayout';
import { AuthCardView, Headline } from '../components/auth';
import { OtpInput } from '../components/forms';
import { MainButton } from '../components/buttons';
import { RESEND_OTP, VERIFY_EMAIL_OTP, VERIFY_PHONE_NUMBER_OTP } from '../redux/slice/auth/auth.actions';
import useVerifyUser from '../react-query/hooks/useVerifyUser';
import useResendOtp from '../react-query/hooks/useResendOtp';
import snackBar from '../react-query/response/SnackBar';
import LoadingLayout from '../layouts/loadingLayout/LoadingLayout';

const validationSchema = Yup.object().shape({
  otp: Yup.string()
    .required('OTP is required')
    .min(6, 'OTP must be at least 6 characters')
    .max(6, 'OTP must be at most 6 characters')
});
const VerificationScreen = () => {
  const dispatch = useDispatch();
  const { pinId, token, phone, userId, role, registrationType } = useParams();
  // console.log(token);
  const { mutate, isLoading } = useVerifyUser(token);

  // handle the reset of time when clicked...
  const initialTimerSeconds = 300; // 300 seconds (5 minutes)

  const [timerSeconds, setTimerSeconds] = useState<number>(
    parseInt(sessionStorage.getItem('otpTimerSeconds') || '0') || initialTimerSeconds
  );

  // Function to format the timer value to mm:ss
  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  // Function to start the timer
  const startTimer = () => {
    setTimerSeconds(initialTimerSeconds); // Reset the timer to 300 seconds (5 minutes)
  };

  // useEffect hook to decrement the timer every second
  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (timerSeconds > 0) {
      interval = setInterval(() => {
        setTimerSeconds((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
      sessionStorage.setItem('otpTimerSeconds', timerSeconds.toString());
    };
  }, [timerSeconds]);

  const formik = useFormik({
    initialValues: {
      otp: ''
    },
    validationSchema,
    onSubmit: async (val) => {
      mutate({ otp: val.otp });
    }
  });

  // Use the useResendOtp hook only if the token exists
  const query = token
    ? useResendOtp(token, 'ACTIVATION')
    : { data: null, isLoading: false, error: null, refetch: undefined };

  const resendOTP = () => {
    // logic to resend OTP here
    if (token && query.refetch) {
      query.refetch();
    }
    startTimer();
  };

  useEffect(() => {
    query.data && snackBar(query.data.message); // Log the data whenever it changes
  }, [query.data]);

  return (
    <LoadingLayout isLoading={isLoading}>
      <AuthScreensLayout>
        <Headline
          title={'Verify your Account!'}
          subtitle={'Enter the 6-digit code sent to your phone number or email address'}
        />
        <AuthCardView>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={formik.handleSubmit}>
              <Stack direction={'column'} spacing={2} alignItems={'center'} justifyContent={'center'}>
                <OtpInput name={'otp'} placeholder={'000000'} />
                <MainButton
                  label={'Verify Account'}
                  color={'primary'}
                  type={'submit'}
                  fullWidth
                  padding={'10px 18px'}
                  loading={isLoading}
                />
              </Stack>
            </Form>
          </FormikProvider>
        </AuthCardView>
        <Stack direction={'column'} spacing={3} alignItems={'center'} justifyContent={'center'} marginTop={3}>
          <Typography
            variant={'body1'}
            component={'span'}
            fontSize={'1.4rem'}
            color={timerSeconds ? '#667085' : '#000'}
            sx={{ cursor: timerSeconds ? 'disable' : 'pointer' }}
            fontWeight={400}
            display={'inline-block'}
            onClick={!timerSeconds ? resendOTP : undefined}
          >
            Resend Code{' '}
            {timerSeconds && timerSeconds > 0 ? (
              <Typography variant={'body1'} component={'span'} color={'#3B7C0F'} fontSize={'1.4rem'} fontWeight={500}>
                {formatTime(timerSeconds)}
              </Typography>
            ) : (
              ''
            )}
          </Typography>
        </Stack>
      </AuthScreensLayout>
    </LoadingLayout>
  );
};

export default VerificationScreen;
