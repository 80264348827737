import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)({
  color: '#3B7C0F',
  textDecoration: 'none',
  textTransform: 'capitalize',
  fontSize: '1.4rem',
  fontFamily: 'Inter'
});
