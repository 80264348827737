import React from 'react';
import { Box, Radio, RadioGroup, FormControlLabel, Typography, Stack, styled } from '@mui/material';
import { MdRadioButtonChecked } from 'react-icons/md';

interface CheckboxWithLabelSelectorProps {
  formik: any;
  dataToRender: any;
  name: string;
  widthClassName?: string;
}

const StyledContainer = styled(Box)({
  width: '100%',
  transition: 'all 0.2s ease',

  '& .optionStyle': {
    transition: '0.2s all',
    margin: '0 0 3.2rem',
    padding: '2.2rem 1.5rem',
    backgroundColor: '#f9fafb',
    borderRadius: '8px',
    width: '-webkit-fill-available'
  },

  '& .selected': {
    transition: '0.2s all',
    margin: '0 0 3.2rem',
    padding: '2.2rem 1.5rem',
    border: '1px solid #027a48',
    backgroundColor: '#f3fee7',
    borderRadius: '8px',
    width: '-webkit-fill-available'
  },

  '& .checkboxWithLabel__label': {
    fontSize: '1.6rem',
    fontWeight: 500,
    marginRight: '1rem'
  }
});

const CheckboxWithLabelSelector = ({ formik, dataToRender, name, widthClassName }: CheckboxWithLabelSelectorProps) => {
  const { values, setFieldValue } = formik;

  const handleChange = (event: any) => {
    setFieldValue(name, event.target.value);
  };
  return (
    <StyledContainer>
      <RadioGroup name={name} value={values[name]} onChange={handleChange}>
        {dataToRender.map(
          (datum: {
            value: string;
            label: string;
            short_desc?: string;
            img?: any;
            disabled?: boolean;
            badge?: string;
          }) => {
            const { value, label, short_desc, img, disabled, badge } = datum;
            return (
              <FormControlLabel
                key={value}
                value={value}
                disabled={disabled}
                control={<Radio size='medium' checkedIcon={<MdRadioButtonChecked fill={'#027A48'} />} />}
                label={
                  <Box sx={{ ml: 1 }}>
                    <Stack direction={'row'} alignItems={'center'}>
                      <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <p className={'checkboxWithLabel__label mb-1'}>{label}</p>
                      </Stack>
                      {img}
                    </Stack>
                    <Box>
                      <Typography
                        variant='body2'
                        component={'span'}
                        color='#667085'
                        fontWeight={400}
                        fontSize={'1.3rem'}
                      >
                        {short_desc}
                      </Typography>
                    </Box>
                  </Box>
                }
                className={
                  values[name] === String(value) ? `selected ${widthClassName}` : `optionStyle ${widthClassName}`
                }
                sx={{ py: 3, flexGrow: 1, mr: 0, mb: 3 }}
              />
            );
          }
        )}
      </RadioGroup>
    </StyledContainer>
  );
};

export default CheckboxWithLabelSelector;
