import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import rootReducer from './rootReducer';

export const history = createBrowserHistory();
export const store = configureStore({
  reducer: rootReducer(history),

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
      thunk: {
        extraArgument: {
          api: {
            get: () => {
              return new Promise((resolve, reject) => {
                resolve(true);
              });
            }
          }
        }
      }
    }),
  devTools: true
});
